import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import {EUR} from 'utils/currency'
import i18n from 'i18n/config'
import time from 'i18n/time'
import {get} from 'lodash'

const stepName  = 'quotationsStep'

const fetchQuotations = (ctx) => {
  const {pickup_values, dropoff_values, transfer_service_values} = ctx

  const query = {
    when: pickup_values.when,
    point_origin: {
      place_type: pickup_values.place_type,
      airport_iata: pickup_values.airport_iata,
      flight_number: pickup_values.flight_number,
      address: pickup_values.address,
    },
    point_dest: {
      place_type: dropoff_values.place_type,
      airport_iata: dropoff_values.airport_iata,
      flight_number: dropoff_values.flight_number,
      address: dropoff_values.address,
    },
    people_detail: {
      adult: transfer_service_values.adult,
      child: transfer_service_values.child,
    },
    car_categories: transfer_service_values.classes,
    car_types: transfer_service_values.categories
  }

  return (
    new ApiFetcher({
      key: 'quotations',
      endpoint: ctx.endpoints.quotations,
      method: 'post',
      body: JSON.stringify({query_parameters: query})
    }).call()
  )
}

const setQuotations = assign({quotations: (ctx, e) => e.data.quotations})
const setGroundInfo = assign({ground_info: (ctx, e) => e.data.ground_info})
const setQuotation = assign({quotation: (ctx, e) => e.quotation})
const setDefaultQuotation = assign({quotation: (ctx, e) => get(e.data, 'quotations[0].service_details[0]')})
const setError = assign({error: i18n.t('validations:quotations_fetch_error')})
const cleanError = assign({error: null})
const cleanQuotations = assign({quotations: null})
const setValidationError = assign({error: i18n.t('validations:quotations_required')})
const quotationSelected = (ctx) => ctx.quotation != null

const quotationsModule = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'prepareFetchingQuotations',
          actions: [cleanError, cleanQuotations]
        }},
      },
      prepareFetchingQuotations: {
        on: {'': {
          target: 'fetchingQuotations',
          actions: ['startSpinner']
        }},
      },
      fetchingQuotations: {
        invoke: {
          src: fetchQuotations,
          onDone: {
            target: 'waitingQuotationPick',
            actions: [setQuotations, setGroundInfo, setDefaultQuotation, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        },
      },
      waitingQuotationPick: {}
    },
    on: {
      QUOTATION_SELECTION: {
        actions: [setQuotation]
      },
      NEXT_STEP_CLICK: [
        {
          target: nextStep(config, stepName),
          // guard: quotationSelected
        },
        {
          actions: [setValidationError]
        }
      ]
    }
  }
}

export default quotationsModule
