import React from 'react'
import AppContext from 'utils/app_context'
import mediaBreakpoints from 'lib/media_breakpoints'
import cssVariables from 'lib/css_variables'
import device from 'react-device-detect'
import {ToastProvider} from 'react-toast-notifications'

const AppProvider = ({user, children}) => {
  const windowSize = [window.innerWidth, window.innerHeight]

  const mediaBreakpoint = mediaBreakpoints.reduce((acc, curr) => {
    if (windowSize[0] > curr.value) {
      return curr.name
    } else {
      if (typeof acc === 'object' && acc !== null) {
        return acc.name
      } else {
        return acc
      }
    }
  })

  return (
    <AppContext.Provider value={{
      device: device,
      windowSize: windowSize,
      cssVariables: cssVariables,
      mediaBreakpoint: mediaBreakpoint,
      user: user
    }}>
      <ToastProvider placement="bottom-right">
        {children}
      </ToastProvider>
    </AppContext.Provider>
  )
}

export default AppProvider
