import React, {useState} from 'react'
import EventItem from 'containers/purchase/modules/event_module/event_item'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import moment from 'moment'

const EventGroups = ({data, eventsActive, eventRenderMode, onEventSelected}) => {
  const [activeTab, setActiveTab] = useState(data[0].id)

  const isActive = (event, eventsActive) => {
    if (!eventsActive) {
      return false
    }

    return Object.values(eventsActive).findIndex((eventActive) => (eventActive && eventActive.id) == event.id) != -1
  }

  const toggle = (tab) => setActiveTab(tab)

  const Tabs = () => {
    return data.map((event_group) => {
      return (
        <NavItem key={event_group.id}>
          <NavLink
            href={'#'}
            className={classnames(activeTab === event_group.id ? ['active', 'bg-primary', 'text-white'] : '', 'font-weight-bold')}
            onClick={() => { toggle(event_group.id) }}
          >
            {event_group.name}
          </NavLink>
        </NavItem>
      )
    })
  }

  const Contents = () => {
    return data.map((event_group) => {
      return (
        <TabPane key={event_group.id} tabId={event_group.id}>
          <div className={eventRenderMode == 'time' ? 'row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 p-0' : ''}>
            <Events events={event_group.events}/>
          </div>
        </TabPane>
      )
    })
  }

  const Events = ({events}) => {
    return events.map((event) =>
      <EventItem
        id={event.id}
        key={event.id}
        eventData={event}
        eventRenderMode={eventRenderMode}
        showAvailability={event.show_availability}
        hour={event.time}
        active={isActive(event, eventsActive)}
        onEventSelected={onEventSelected}
        availability={event.availability}
      />
    )
  }

  return (
    <React.Fragment>
      <Nav tabs><Tabs /></Nav>
      <TabContent activeTab={activeTab} className={'my-2'}>
        <Contents />
      </TabContent>
    </React.Fragment>
  )
}

export default EventGroups
