import {assign} from 'xstate'
import {nextStep} from 'utils/step_utils'

const stepName  = 'iframeStep'

const endpoint = 'https://portal-pre.oneboxtickets.net/onebox_api/widgets/ob-widgets?rootPath=https://portal-pre.oneboxtickets.net'

const loadIframeLink = () => {
  // const script = document.createElement("script")
  // script.src = endpoint
  // script.async = true
  // document.body.appendChild(script)
  //
  // window.onload = function () {
  //     _onebox.moduleLoader.loadWidget("widget-container");
  // }
}

// const loadIframeLink = () => {
//
// }

const initializeWidget = (ctx) => {
  // _onebox.moduleLoader.loadWidget('widget-container')
  //
  // pubsub.subscribe('/cart/updated', (e) => {
  //   send('CART_UPDATED', e.data)
  // })
}

const setOneboxCart = assign({onebox_cart: (ctx, e) => e})
const setTickets = assign({tickets: (ctx, e) => {
  if (!e.cartItems) {
    return null
  }

  return e.cartItems.map((item) => {
    return {
      id: item.id.toString(),
      name: item.ticketData.rateName,
      qty: 1
    }
  })
}})

const setNextReady = assign({next_ready: (ctx, e) => e.cartItems.length > 0 ? true : false})
const setNextUnready = assign({next_ready: (ctx, e) => false})
const setReservationReady = assign({reservation_ready: (ctx, e) => e.cartItems.length > 0 ? true : false})
const setReservationUnready = assign({reservation_ready: (ctx, e) => false})

const iframeModule = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {
          '': [
            {
              target: 'initializeWidget',
            }
          ],
        },
      },
      initializeWidget: {
        on: {
          '': [
            {
              target: 'waitingIframeResponse',
              actions: [setNextUnready, setReservationUnready, initializeWidget]
            }
          ],
        },
      },
      waitingIframeResponse: {}
    },
    on: {
      NEXT_STEP_CLICK: [
        {
          target: '#purchase.' + nextStep(config, stepName)
        }
      ],
      CART_UPDATED: {
        actions: [setOneboxCart, setTickets, setNextReady, setReservationReady]
      },
    }
  }
}

export default iframeModule
