import React, {useState, useEffect} from 'react'
import LoginForm from './login_form'
import 'stylesheets/sign_in'
import poweredOcio from 'img/powered-ociobusiness'
import Modal from 'ui/modal'
import AppProvider from 'utils/app_provider'
import LanguageSwitcher from 'ui/language_switcher'
import ContactForm from 'containers/layouts/contact_form'
import { useTranslation } from 'react-i18next'
import {usePartial} from 'i18n/config'
import {themeBydomain} from 'lib/themes'
import clientConfig from 'config/client_config.json'
import bgDefault from 'img/bg-default.jpg'

const SignIn = ({endpoint, urls, site_name}) => {
  const {supportTelephone, supportEmail} = clientConfig
  const theme = themeBydomain()
  const {i18n} = useTranslation()

  useEffect(() => {
    const bg = localStorage.getItem('sign_in_bg')
    document.getElementsByTagName('body')[0].style = `background-image: url(${bg ? bg : bgDefault})`
  }, [])

  const LegalWarningLink = () => {
    const LegalWarning = usePartial('auth/legal_warning', {supportTelephone: supportTelephone, site_name: site_name})
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <React.Fragment>
        <a className="text-white" style={{cursor: 'pointer'}} onClick={() => toggle()}>{i18n.t('sign_in.legal_warning')}</a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          header={i18n.t('sign_in.legal_warning')}
          width={'90%'}
        >
          <LegalWarning supportTelephone={supportTelephone}/>
        </Modal>
      </React.Fragment>
    )

  }

  const ReturnPolicyLink = () => {
    const ReturnPolicy = usePartial('auth/return_policy', {supportTelephone: supportTelephone, site_name: site_name})
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <React.Fragment>
        <a className="text-white" style={{cursor: 'pointer'}} onClick={() => toggle()}>{i18n.t('sign_in.return_policy')}</a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          header={i18n.t('sign_in.return_policy')}
          width={'90%'}
        >
          <ReturnPolicy supportTelephone={supportTelephone}/>
        </Modal>
      </React.Fragment>
    )
  }

  const SignInLink = () => {
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <React.Fragment>
        <div className="text-white mt-3">
          {i18n.t('sign_in.new_user_question')} <a onClick={() => setModal(true)}><strong className="text-primary" style={{cursor: 'pointer'}}>{i18n.t('format:actions.click_here')}</strong></a> {i18n.t('sign_in.more_info')}
        </div>

        <Modal
          isOpen={modal}
          toggle={toggle}
          header={i18n.t('sign_in.contact_us')}
          width={'800px'}
        >
          <ContactForm/>
        </Modal>
      </React.Fragment>
    )
  }

  const ContactLink = () => {
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <React.Fragment>
        <a className="text-white" style={{cursor: 'pointer'}} onClick={() => toggle()}>{i18n.t('sign_in.contact_us')}</a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          header={i18n.t('sign_in.contact_us')}
          width={'800px'}
        >
          <ContactForm/>
        </Modal>
      </React.Fragment>
    )
  }

  const WhatWeDoModal = () => {
    const WhatWeDo = usePartial('auth/what_we_do')
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <React.Fragment>
        <a className="text-white" style={{cursor: 'pointer'}} onClick={() => toggle()}>{i18n.t('sign_in.what_we_do')}</a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          header={i18n.t('sign_in.what_we_do')}
          width={'800px'}
        >
          <WhatWeDo/>
        </Modal>
      </React.Fragment>
    )
  }

  const WhatWeOfferModal = () => {
    const WhatWeOffer = usePartial('auth/what_we_offer')
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <React.Fragment>
        <a className="text-white" style={{cursor: 'pointer'}} onClick={() => toggle()}>{i18n.t('sign_in.what_we_offer')}</a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          header={i18n.t('sign_in.what_we_offer')}
          width={'800px'}
        >
          <WhatWeOffer/>
        </Modal>
      </React.Fragment>
    )
  }

  const OurProductsModal = () => {
    const OurProducts = usePartial('auth/our_products')
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <React.Fragment>
        <a className="text-white" style={{cursor: 'pointer'}} onClick={() => toggle()}>{i18n.t('sign_in.our_products')}</a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          header={i18n.t('sign_in.our_products')}
          width={'800px'}
        >
          <OurProducts/>
        </Modal>
      </React.Fragment>
    )
  }

  return (
    <AppProvider>
      <div className="home-container">
        <div className="home-topbar">
          <ul className="contact-list">
            <li><a href={`tel:${supportTelephone}`}><i className="fa fa-phone mr-2"></i>{supportTelephone}</a></li>
            <li><a href={`mailto:${supportEmail}`}><i className="fa fa-envelope mr-2"></i>{supportEmail}</a></li>
          </ul>
        </div>

        <div className="login-box">
          <div className="home-languages">
            <LanguageSwitcher en es fr urls={urls}/>
          </div>
          <div className="login-box-header">
            {i18n.t('sign_in.login_box_title')}
          </div>
          <div className="login-box-body">
            <LoginForm endpoint={endpoint}/>

            <SignInLink/>
          </div>
        </div>

        <div className="side-call-wrapper">
          <div className="side-call first">
            <WhatWeDoModal/>
          </div>

          <div className="side-call second">
            <WhatWeOfferModal/>
          </div>

          <div className="side-call third">
            <OurProductsModal/>
          </div>
        </div>

        <div className="home-footer">
          <ul className="footer-menu">
            <li><LegalWarningLink/></li>
            <li><ReturnPolicyLink/></li>
            <li><ContactLink/></li>
          </ul>

          <div className="mobile-logo">
            <a href="http://ociobusiness.es/" target="_blank" rel="noreferrer">
              <img width="90px" src=""></img>
            </a>
          </div>
        </div>

        <div className="logo-bottom">
          <img src={theme.sing_in_logo}/>
        </div>

        <div className="corner-top"></div>
        <div className="corner-bottom">
          <a href="http://ociobusiness.es/" target="_blank" rel="noreferrer">
            <img width="90px" src={poweredOcio}></img>
          </a>
        </div>
      </div>
    </AppProvider>
  )
}

export default SignIn
