import React from 'react'
import i18n from 'i18n/config'
import spainFlag from 'img/flags/Spain'
import ukFlag from 'img/flags/UnitedKingdom'
import franceFlag from 'img/flags/France'
import mexicoFlag from 'img/flags/Mexico'
import chinaFlag from 'img/flags/China'
import styled from 'styled-components'

const FlagLink = styled.a`
  img {
    ${({active}) => !active &&`
      filter: gray; /* IE5+ */
      -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
      -webkit-transition: all .2s ease-in-out;
    `}


    &:hover {
      filter: none;
        -webkit-filter: grayscale(0);
        -webkit-transform: scale(1.01);
      }
    }
  }
`

const LanguageSwitcher = (props) => {
  const {en = true, es = true, es_mx = true, fr = false, zh = false, urls} = props

  const host = window.location.host
  const isMexicoDomain = host.includes('.mx')

  return (
    <React.Fragment>
      {en &&
        <FlagLink active={i18n.language == 'en'} href={urls.en} title={'English'} className="mr-2">
          <img width="26" height="26" src={ukFlag} alt="United Kingdom English language flag"/>
        </FlagLink>
      }
      {es && !isMexicoDomain &&
        <FlagLink active={i18n.language == 'es'} href={urls.es} title={'Español'} className="mr-2">
          <img width="26" height="26" src={spainFlag} alt="Español language flag"/>
        </FlagLink>
      }
      {es_mx && isMexicoDomain &&
        <FlagLink active={i18n.language == 'es_mx'} href={urls.es_mx} title={'Español'} className="mr-2">
          <img width="26" height="26" src={mexicoFlag} alt="Español language flag"/>
        </FlagLink>
      }
      {fr &&
        <FlagLink active={i18n.language == 'fr'} href={urls.fr} title={'France'} className="mr-2">
          <img width="26" height="26" src={franceFlag} alt="Français language flag "/>
        </FlagLink>
      }
      {zh &&
        <FlagLink active={i18n.language == 'zh'} href={urls.zh} title={'China'}>
          <img width="26" height="26" src={chinaFlag} alt="China language flag "/>
        </FlagLink>
      }
    </React.Fragment>
  )
}

export default LanguageSwitcher
