import React, {useState, useEffect, useRef} from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import { useTranslation } from 'react-i18next'
import {Row, Col, ButtonGroup, Button, ListGroup, ListGroupItem, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardHeader, CardBody, CardFooter} from 'reactstrap'
import styled from 'styled-components'
import inicial from 'img/iryo/inicial.svg'
import singular from 'img/iryo/singular.svg'
import singularOnlyYou from 'img/iryo/singular-only-you.svg'
import infinita from 'img/iryo/infinita.svg'
import infinitaBistro from 'img/iryo/infinita-bistro.png'
import train from 'img/iryo/iryo-train'
import time from 'i18n/time'
import {currency} from 'i18n/config'
import Modal from 'ui/modal'
import {get} from 'lodash'
import {initialGroup, singularGroup, singularOnlyYouGroup, infinitaGroup, infinitaBistroGroup, arrowPositions, colorClasses} from './classes'
import cn from 'classnames'
import {scrollTo, scrollIntoView} from 'scroll-js'

const Cell = styled(Col)`
  padding: 0.25em;
`;

const CellContent = styled.div`
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  font-family: emeric,sans-serif;
  font-weight: 400;
  height: 95px;
  font-size: .8rem;
  opacity: 1;
  transition: all .5 cubic-bezier(.25,.8,.25,1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  color: #000;
  border-bottom: 4px solid #f2eef0;
  font-size: 1.25rem;
  color: #584c53;
  font-weight: 600;
`;

const CellClickable = styled(CellContent)`
  cursor: ${props => props.active ? 'pointer' : 'auto'};

  &:hover {
    background-color: #fff;
    box-shadow: 0 11px 44px #00000036;
  }
`;

const Header = styled(Cell)`
  background: transparent;
  border-bottom-color: #1d1d1b;
`;

const Inicial = styled(CellClickable)`
  border-bottom-color: #864a33;
`;

const Singular = styled(CellClickable)`
  border-bottom-color: #a6192e;
`;

const SingularOnlyYou = styled(CellClickable)`
  border-bottom-color: #a6192e;
`;

const Infinita = styled(CellClickable)`
  border-bottom-color: #1d1d1b;
`;

const InfinitaBistro = styled(CellClickable)`
  border-bottom-color: #1d1d1b;
`;

const Panel = styled.div`
  position:relative;

  .header {
    background: ${props => colorClasses(props.product)};
    color: white;
    text-transform: uppercase;
  }

  .body {
    border: 4px solid ${props => colorClasses(props.product)};
    border-top: 0px;
  }

  .arrow {
    position: absolute;
    color: ${props => colorClasses(props.product)};
    bottom: 100%;
    left: ${props => arrowPositions(props.product)};
    margin-left: -1rem;
    width: 2rem;
    height: 1rem;
    overflow: hidden;
    border-right: 23px solid transparent;
    border-top: 23px solid transparent;
    border-left: 23px solid transparent;
    border-bottom: 23px solid;
  }
`

export const IryoTrainModule = (props) => {
  const {config, machine, status} = props
  const [current, send] = machine
  const {route_values, availability, outbound, inbound, activeTab} = current.context
  const { t, i18n } = useTranslation()
  // const [outbound, setOutbound] = useState()
  // const [inbound, setInbound] = useState()
  const [scrollReady, setScrollReady] = useState(true)
  // const [activeTab, setActiveTab] = useState('outbound')
  const outboundRoutes = get(availability, 'offer.travels[0].routes')
  const inboundRoutes = get(availability, 'offer.travels[1].routes')
  const products = get(availability, 'products', [])
  const features = get(availability, 'features', [])
  const specialRequests = get(availability, 'special_requests', [])
  const allergensList = get(availability, 'allergens', [])

  const [bistroModal, setBistroModal] = useState()
  const toggleBistroModal = () => setBistroModal(!bistroModal)

  const corporateCode = get(config, 'product.external_api_config.corporateCode')

  const setOutbound = (outbound) => {
    return send({type: 'OUTBOUND_CHANGE', outbound: outbound})
  }

  const setInbound = (inbound) => {
    return send({type: 'INBOUND_CHANGE', inbound: inbound})
  }

  const setActiveTab = (tab) => {
    return send({type: 'TAB_CHANGE', tab: tab})
  }

  const Departures = (props) => {
    const {type, selected, setSelected, departureArrival} = props

    const product = products.find((p) => p.code == (selected && selected.product.items[0].product_code))
    const availableFeatures = features && product && features.filter((f) => product.features.includes(f.code))

    let routes = props.routes
    if (type == 'inbound') {
      const limitTime = time(departureArrival).add(1, 'hours')
      routes = routes.filter((r) => time(r.legs[0].departure_station.departure_timestamp) >= limitTime)
    }

    if (!routes || routes.length <= 0) {
      return <NoRoutesAlert/>
    }

    return (
      <div>
        <Row className="text-center">
          <Header style={{flexBasis: '17%'}}>
          </Header>
          <Header>
            <img width="100px" src={inicial}/>
          </Header>
          <Header>
            <img  width="100px" src={singular}/>
          </Header>
          <Header>
            <img width="100px" src={singularOnlyYou}/>
          </Header>
          <Header>
            <img width="130px" src={infinitaBistro}/>
          </Header>
        </Row>
        {routes.map((r) => {
          const departureTime = time(r.legs[0].departure_station.departure_timestamp)
          const arrivalTime = time(r.legs[0].arrival_station.arrival_timestamp)
          const interval = time.duration(arrivalTime.diff(departureTime, 'minutes'), 'minutes')
          const initialProduct = r.bundles.find((b) => b.items[0].product_code == 'PT_FLEX2')
          const singularProduct = r.bundles.find((b) => b.items[0].product_code == 'PS_FLEX2')
          const singularAbiertaProduct = r.bundles.find((b) => b.items[0].product_code == 'PS_FLEX1')
          const singularOnlyYouProduct = r.bundles.find((b) => b.items[0].product_code == 'PS_CFFLEX2')
          const infinitaProduct = r.bundles.find((b) => b.items[0].product_code == 'PI_FLEX2')
          const singularOnlyYouAbiertaProduct = r.bundles.find((b) => b.items[0].product_code == 'PS_CFFLEX1')
          const infinitaAbiertaProduct = r.bundles.find((b) => b.items[0].product_code == 'PI_FLEX1')
          const infinitaBistroProduct = r.bundles.find((b) => b.items[0].product_code == 'PI_GRFLEX2')
          const infinitaBistroAbiertaProduct = r.bundles.find((b) => b.items[0].product_code == 'PI_GRFLEX1')
          const serviceName = r.legs[0].service_name

          const singularAbiertaLabel = singularProduct && products.find((p) => singularAbiertaProduct.items[0].product_code == p.code).name
          const singularOnlyYouAbiertaLabel = singularOnlyYouProduct && products.find((p) => singularOnlyYouAbiertaProduct.items[0].product_code == p.code).name
          const infinitaAbiertaLabel = infinitaAbiertaProduct && products.find((p) => infinitaAbiertaProduct.items[0].product_code == p.code).name

          const onConfirmProduct = () => {
            if (type == 'outbound' && inboundRoutes) {
              const element = document.getElementById('iryo-train-module')
              // element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
              scrollIntoView(element, document.body, { behavior: 'smooth' })

              setActiveTab('inbound')
            } else {
              send({type: 'CONFIRMATION'})
            }
          }

          const productIsActive = (product) => {
            if (!product) { return false }

            if (corporateCode && !product.ppc[0]) { return false }

            return true
          }

          const priceLabel = (product) => {
            if (!product) { return '-'}

            if (corporateCode && !product.ppc[0]) { return '-' }

            if (corporateCode) {
              return (
                <div>
                  <div>{currency(product.ppc[0])}</div>
                  <small className="text-danger"><strike>{currency(product.price)}</strike></small>
                </div>

              )
            } else {
              return (
                <div>
                  <div>{currency(product.price)}</div>
                  {false &&
                    <small><i style={{color: '#939699', fontSize: '0.8em'}}>{i18n.t('iryo_train_module.fee_included', {amount: currency(product.fee)})}</i></small>
                  }
                </div>
              )
            }
          }

          const ProductDetails = () => {
            const productImage = (code) => {
              if (initialGroup.includes(code)) {
                return inicial
              } else if (singularGroup.includes(code)) {
                return singular
              } else if (singularOnlyYouGroup.includes(code)) {
                return singularOnlyYou
              } else if (infinitaGroup.includes(code)) {
                return infinita
              } else if (infinitaBistroGroup.includes(code)) {
                return infinitaBistro
              }
            }

            const addons_req = selected.product.items[0].addons_req

            const addonsData = addons_req.map((addon_id) => typeof addon_id !== "undefined" && availability.offer.travels[0].addons[addon_id])

            const snackAddon = addonsData.find((addon) => addon.product_code == 'SNCK_R')

            const requiredAddon = selected.product.items[0].addons_req[0]

            const addonsWithoutSnack = addonsData.filter((addon) => addon.product_code != 'SNCK_R')
            const addon = addonsWithoutSnack && addonsWithoutSnack[0]
            const addonText = addon && availability.offer.texts.find((text) => text.code == addon.product_code)

            const filteredSpecialRequests = addonText && specialRequests.filter((sr) => addonText.allowedSpecialRequests && addonText.allowedSpecialRequests[0].codes.includes(sr.code))

            const BistroModal = () => {
              const passengers = availability.offer.passengers.filter((p) => p.type != 'IN')

              const menuSelectPending = passengers
                .map((passenger) => selected.menus && selected.menus[passenger.id])
                .some((selected) => !selected)

              const onSelectMenuForAll = (addon, specialRequest) => {
                let newMenus = {}

                passengers.map((passenger, i) => {
                  let passengerAddons = [
                    {
                      passengerId: passenger.id,
                      tariffCode: addon.passenger_fares[i].tariff_code,
                      specialRequests: [
                        {
                          type: specialRequest.type,
                          code: specialRequest.code
                        }
                      ]
                    }
                  ]

                  if (snackAddon) {
                    passengerAddons.push({
                      passengerId: passenger.id,
                      tariffCode: snackAddon.passenger_fares[i].tariff_code,
                    })
                  }

                  newMenus[passenger.id] = passengerAddons
                })

                setSelected({...selected, menus: newMenus})
              }

              return (
                <Modal
                  isOpen={bistroModal}
                  toggle={toggleBistroModal}
                  header={i18n.t('bistro_modal.title')}
                  closeOverlay={false}
                >
                  <Row className={cn(['row row-cols-1 row-cols-md-2 mx-0 mb-3', passengers.length > 2 ? 'justify-content-start' : 'justify-content-center'])}>
                    {passengers.map((passenger, i) => {
                      const passenger_type = availability.passenger_types.find((pt) => pt.code == passenger.type)
                      const fare = selected.product.items[0].passenger_fares[i]
                      const product = availability.products.find((p) => p.code == fare.product_code)

                      const [menuModal, setMenuModal] = useState()
                      const toggleMenuModal = () => setMenuModal(!menuModal)

                      const Menus = (props) => {
                        const {enableSelectAll} = props

                        return (
                          <div style={{overflowY: 'scroll', height: '550px'}}>
                            {filteredSpecialRequests.filter(({type}) => type == 'ME').map((request) => {
                              const {code, type, name, description, allergens} = request

                              const allergensData = allergensList.filter((a) => allergens && allergens.includes(a.code))

                              const onSelectMenu = (passenger, addon, specialRequest) => {
                                let passengerAddons = [{
                                  passengerId: passenger.id,
                                  tariffCode: addon.passenger_fares[i].tariff_code,
                                  specialRequests: [
                                    {
                                      type: specialRequest.type,
                                      code: specialRequest.code
                                    }
                                  ]
                                }]

                                if (snackAddon) {
                                  passengerAddons.push({
                                    passengerId: passenger.id,
                                    tariffCode: snackAddon.passenger_fares[i].tariff_code,
                                  })
                                }

                                let newMenus = {}

                                if (selected.menus) { newMenus = selected.menus }

                                newMenus[passenger.id] = passengerAddons
                                setSelected({...selected, menus: newMenus})
                              }

                              return (
                                <Col xs="12" className="mb-3">
                                  <Card>
                                    <CardHeader>
                                      <strong>{name}</strong>
                                    </CardHeader>
                                    <CardBody>
                                      {description}

                                      {allergensData && allergensData.length > 0 &&
                                        <React.Fragment>
                                          <hr/>

                                          <i>{i18n.t('menus.allergens')}: {allergensData.map((ad) => ad.name).join(', ')}</i>
                                        </React.Fragment>
                                      }
                                    </CardBody>
                                    <CardFooter>
                                      <ButtonGroup className="btn-block">
                                        <Button color="primary text-white" onClick={() => onSelectMenu(passenger, addon, request)}>
                                          {i18n.t('format:actions.select')}
                                        </Button>
                                        {passengers.length > 1 &&
                                          <Button onClick={() => onSelectMenuForAll(addon, request)}>
                                            {i18n.t('format:actions.select_for_all')}
                                          </Button>
                                        }
                                      </ButtonGroup>
                                    </CardFooter>
                                  </Card>
                                </Col>
                              )
                            })}
                          </div>
                        )
                      }

                      const MenuModal = () => {
                        return (
                          <Modal
                            isOpen={menuModal}
                            toggle={toggleMenuModal}
                            header={i18n.t('menu_modal.title')}
                            closeOverlay={false}
                          >
                            <Menus/>
                          </Modal>
                        )
                      }

                      const selectedMenu = selected.menus && selected.menus[passenger.id] && selected.menus[passenger.id].filter((addon) => {
                        if (!snackAddon) { return true }

                        return addon.tariffCode != snackAddon.passenger_fares[i].tariff_code
                      })[0]
                      const specialRequest = selectedMenu && filteredSpecialRequests.find((sr) => selectedMenu.specialRequests[0].code == sr.code)
                      const specialRequestName = specialRequest && specialRequest.name

                      return (
                        <Col>
                          <MenuModal/>
                          <Card className="mb-3">
                            <CardHeader>
                              <div className="d-flex justify-content-between">
                                <div>
                                  {specialRequestName ? (
                                    <span>{passenger_type.description}: {specialRequestName}</span>
                                  ) : (
                                    <span>{passenger_type.description}</span>
                                  )}
                                </div>
                                <div>
                                  {specialRequestName &&
                                    <i className="fa fa-check text-success"/>
                                  }
                                </div>
                              </div>

                            </CardHeader>
                            <CardBody>
                              <Button onClick={() => setMenuModal(true)} className="btn-block" color={specialRequestName ? "dark" : "info"}>
                                {selectedMenu ? 'Cambiar menú' : 'Elegir menú'}
                              </Button>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    })}
                  </Row>

                  <hr/>

                  <Row>
                    <Col className="text-center">
                      <Button
                        disabled={menuSelectPending}
                        color="success"
                        onClick={() => {
                          setBistroModal(false)
                          onConfirmProduct()
                        }}
                      >
                        Confirmar tarifa y menús
                      </Button>
                    </Col>
                  </Row>
                </Modal>
              )
            }

            return (
              <Row>
                {addonText &&
                  <BistroModal/>
                }
                <Col>
                  <Panel product={product && product.code} className="shadow mt-4 mb-2" >
                    <div className="arrow">A</div>
                    <div className="header d-flex justify-content-between py-3 px-4">
                      <div className="text-uppercase">{i18n.t('iryo_train_module.features_title')}</div>
                      <div style={{cursor: 'pointer'}} onClick={() => type == 'outbound' ? setOutbound(null) : setInbound(null)}><i className="fa fa-times"></i></div>
                    </div>
                    <div className="body p-4">
                      <Row>
                        <Col xs="9">
                          <Row className="row-cols-lg-3">
                            {availableFeatures && availableFeatures.map(({code, name, description}) => {
                              return (
                                <Col key={code}>
                                  <span className="text-uppercase font-weight-bold"><i className="fa fa-angle-right" aria-hidden="true"></i> {name}</span>
                                  <p>
                                    {description}
                                  </p>
                                </Col>
                              )
                            })}
                          </Row>
                          {(singularGroup.includes(product && product.code) || singularOnlyYouGroup.includes(product && product.code) || infinitaGroup.includes(product && product.code)) &&
                            <Row>
                              <Col className="pt-3">
                                <div className="pt-3" style={{borderTop: '1px solid', borderColor: colorClasses(product && product.code)}}>
                                  <div className="text-uppercase font-weight-bold" style={{fontSize: '1.2em', color: colorClasses(product && product.code)}}>{i18n.t('iryo_train_module.other_products')}</div>
                                  {singularGroup.includes(product && product.code) &&
                                    <div className="pt-2 pl-3 text-uppercase" style={{cursor: 'pointer', color: colorClasses(product.code)}} onClick={() => product && product.code == 'PS_FLEX1' ? onProductClick(singularProduct) : onProductClick(singularAbiertaProduct)}>
                                      <input type="checkbox" checked={product && product.code == 'PS_FLEX1'}/> {singularAbiertaLabel}
                                    </div>
                                  }
                                  {singularOnlyYouGroup.includes(product && product.code) &&
                                    <div className="pt-2 pl-3 text-uppercase" style={{cursor: 'pointer', color: colorClasses(product.code)}} onClick={() => product && product.code == 'PS_CFFLEX1' ? onProductClick(singularOnlyYouProduct) : onProductClick(singularOnlyYouAbiertaProduct)}>
                                      <input type="checkbox" checked={product && product.code == 'PS_CFFLEX1'}/> {singularOnlyYouAbiertaLabel}
                                    </div>
                                  }
                                  {infinitaGroup.includes(product && product.code) &&
                                    <div className="pt-2 pl-3 text-uppercase" style={{cursor: 'pointer', color: colorClasses(product.code)}} onClick={() => product && product.code == 'PI_FLEX1' ? onProductClick(infinitaProduct) : onProductClick(infinitaAbiertaProduct)}>
                                      <input type="checkbox" checked={product && product.code == 'PI_FLEX1'}/> {infinitaAbiertaLabel}
                                    </div>
                                  }
                                </div>
                              </Col>
                            </Row>
                          }
                        </Col>
                        <Col xs="3" className="d-flex justify-content-center align-items-center bg-light">
                          <div className="d-flex flex-column justify-content-center">
                            <div className="w-100 d-flex justify-content-center">
                              <img width="100" src={productImage(product.code)}/>
                            </div>
                            <span className="text-center p-3" style={{fontSize: '1.5em', fontWeight: 'bold'}}>{priceLabel(selected.product)}</span>

                            {infinitaBistroGroup.includes(product && product.code) ? (
                              <button onClick={() => setBistroModal(true)} className="bg-primary text-white text-uppercase btn-block px-4 py-2">Elegir menú</button>
                            ) : (
                              <button onClick={onConfirmProduct} className="bg-primary text-white text-uppercase btn-block px-4 py-2">{i18n.t('format:actions.select')}</button>
                            )}

                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                </Col>
              </Row>
            )
          }

          const [menus, setMenus] = useState({})

          const serviceRowId = `service-${r.legs[0].service_name}`

          const onProductClick = (product) => {
            if (!product) { return '' }

            if (corporateCode && !product.ppc[0]) { return '' }

            const routeParams = {
              route: r,
              product: product
            }

            if (type == 'outbound') {
              setOutbound(routeParams)
            } else {
              setInbound(routeParams)
            }
          }

          return (
            <React.Fragment key={r.id}>
              <Row id={serviceRowId} className="no-gutters text-center">
                <Cell style={{flexBasis: '17%'}} className="pl-0 pr-1">
                  <CellContent style={{background: 'white'}}>
                    <div className="d-flex justify-content-center" style={{color: '#b28e99', fontSize: '0.75em', fontWeight: 'normal'}}>
                      {interval.format('h[h] mm[m]')}
                    </div>
                    <div className="d-flex justify-content-center align-items-center px-5 pb-2" style={{width: '100%'}}>
                      <div className="pr-3" style={{flexBasis: '20px', gap: '0.5rem'}}>
                        {departureTime.format('LT')}
                      </div>
                      <div style={{borderBottom: '4px dashed #b28e99', flexGrow: '5'}}></div>
                      <div className="px-3" style={{color: '#b28e99'}}><i className="fa fa-train" aria-hidden="true"/></div>
                      <div style={{borderBottom: '4px dashed #b28e99', flexGrow: '5'}}></div>
                      <div className="pl-3">
                        {arrivalTime.format('LT')}
                      </div>
                    </div>
                  </CellContent>
                </Cell>
                <Cell className="px-1" onClick={() => onProductClick(initialProduct)}>
                  <Inicial active={productIsActive(initialProduct)}>
                    {priceLabel(initialProduct)}
                  </Inicial>
                </Cell>
                <Cell className="pl-1 pr-0" onClick={() => onProductClick(singularProduct)}>
                  <Singular active={productIsActive(singularProduct)}>
                    {priceLabel(singularProduct)}
                  </Singular>
                </Cell>
                <Cell className="px-1" onClick={() => onProductClick(singularOnlyYouProduct)}>
                  <SingularOnlyYou active={productIsActive(singularOnlyYouProduct)}>
                    {priceLabel(singularOnlyYouProduct)}
                  </SingularOnlyYou>
                </Cell>
                <Cell className="pl-1 pr-0" onClick={() => onProductClick(infinitaBistroProduct)}>
                  <Infinita active={productIsActive(infinitaBistroProduct)}>
                    {priceLabel(infinitaBistroProduct)}
                  </Infinita>
                </Cell>
                {false &&
                  <Cell className="pl-1 pr-0" onClick={() => onProductClick(infinitaProduct)}>
                    <Infinita active={productIsActive(infinitaProduct)}>
                      {priceLabel(infinitaProduct)}
                    </Infinita>
                  </Cell>
                }
              </Row>
              {scrollReady && product && (serviceName == selected.route.legs[0].service_name) &&
                <ProductDetails/>
              }
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  const NoRoutesAlert = () => {
    return (
      <div className="alert alert-info my-3">
        {i18n.t('iryo_train_module.no_routes_alert')}
      </div>
    )
  }

  const ProductBreadcrumb = (props) => {
    const {active, type, travel} = props

    return (
      <div className="p-2 rounded d-flex justify-content-center align-items-center" style={{backgroundColor: active ? '#fff3d0' : ''}}>
        <div className="mr-2" style={{transform: type == 'inbound' ? 'scaleX(-1)' : ''}}>
          <img className="align-self-center" src={train}/>
        </div>
        <div className="text-left">
          <div style={{fontSize: '0.8em'}}>
            <span className="text-uppercase text-muted mr-2">
              {type == 'outbound' ? (
                i18n.t('iryo_train_module.one_way')
              ) : (
                i18n.t('iryo_train_module.round_trip')
              )}:
            </span>
            {travel &&
              <span style={{fontSize: '1.5em'}} className="font-weight-bold">{time(travel.route.legs[0].departure_station.departure_timestamp).format('LL')}</span>
            }
          </div>
          {travel ? (
            <div className="d-flex justify-content-start">
              <div className="pr-3">
                <span className="font-weight-bold">{i18n.t('iryo_train_module.time')}:</span> {time(travel.route.legs[0].departure_station.departure_timestamp).format('LT')}
              </div>
              <div className="px-3">
                <span className="font-weight-bold">{products.find((p) => p.code == travel.product.items[0].product_code).name}: </span>
                {currency(travel.product.price)}
              </div>
            </div>
          ) : (
            <div>
              {type == 'inbound' && !active ? '-' : <strong>{i18n.t('iryo_train_module.select_modality')}</strong>}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <ModulePanel id="iryo-train-module" className="hola">
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_TRAINSTEP') : null}>
        <span>
          <i className="fa fa-clock mr-2" aria-hidden="true"></i>
          {props.title}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        <Nav tabs fill>
          <NavItem
            style={{cursor: 'pointer'}}
            onClick={() => {
              if (inboundRoutes && activeTab != 'outbound') {
                setOutbound(null)
                setInbound(null)
                setActiveTab('outbound')
              }
            }}
          >
            <NavLink
              className={cn('p-1', {active: activeTab == 'outbound', disabled: activeTab != 'outbound'})}
            >
              <ProductBreadcrumb active={activeTab == 'outbound'} type="outbound" travel={outbound}/>
            </NavLink>
          </NavItem>
          {inboundRoutes &&
            <NavItem>
              <NavLink
                className={cn('p-1', {active: activeTab == 'inbound', disabled: activeTab != 'inbound'})}
              >
                <ProductBreadcrumb active={activeTab == 'inbound'} type="inbound" travel={inbound}/>
              </NavLink>
            </NavItem>
          }
        </Nav>
        <TabContent className="p-3" activeTab={activeTab} style={{border: '1px solid #dee2e6', borderTop: '0'}}>
          <TabPane tabId="outbound">
            {outboundRoutes ? (
              <Departures type="outbound" routes={outboundRoutes} selected={outbound} setSelected={setOutbound}/>
            ) : (
              <NoRoutesAlert/>
            )}
          </TabPane>
          {inboundRoutes &&
            <TabPane tabId="inbound">
              {inboundRoutes ? (
                <Departures type="inbound" routes={inboundRoutes} selected={inbound} setSelected={setInbound} departureArrival={get(outbound, 'route.legs[0].arrival_station.arrival_timestamp')}/>
              ) : (
                <NoRoutesAlert/>
              )}
            </TabPane>
          }
        </TabContent>
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default IryoTrainModule
