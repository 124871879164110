import ApiFetcher from 'lib/api_fetcher'
import AppProvider from 'utils/app_provider'
import React, { useState, useEffect } from 'react'
import {isBrowser, isMobile} from 'react-device-detect'
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  ListGroup,
  ListGroupItem,
  Button
} from 'reactstrap'
import Modal from 'ui/modal'
import {useTranslation} from 'react-i18next'
import ContactForm from './contact_form'
import DestinationSelector from './destination_selector'
import SearchBox from './search_box'
import LanguageSwitcher from 'ui/language_switcher'
import CartSummary from './cart_summary'
import {themeBydomain} from 'lib/themes'
import {get} from 'lodash'
import time from 'i18n/time'

const AppNavbar = (props) => {
  const {cart, cart_params, client_params} = props
  const {enable_cart, auto_open_cart} = cart_params
  const {root, links, user, language_links, destination_id, destination_link, change_destination_link, product_link, logout_link, show_menu} = props.menu_props
  const [isOpen, setIsOpen] = useState(false)

  const {theme} = user.user_data.user

  const toggle = () => setIsOpen(!isOpen)

  const goToPage = (url) => window.location.href = url

  const destinations = user.user_data.user.destinations
  const channel = user.user_data.user.sale_channel

  const TopBar = () => {
    return (
      <div className="topbar text-white p-2">
        <div className="container d-md-flex align-items-center">
          <div className="mr-auto">
            <div className="d-flex d-md-inline-flex flex-column flex-sm-row">
              <div className="text-center px-2 pl-sm-0">
                <i className="fa fa-phone mr-2"/><a className="text-white" href={`tel:${client_params.support_telephone}`}>{client_params.support_telephone_label}</a>
              </div>
              <div className="text-center px-2">
                <i className="fa fa-envelope mr-1"/><a className="text-white" href={`mailto:${client_params.support_email}`}>{client_params.support_email}</a>
              </div>
              {isMobile &&
                <div className="text-center px-2 rounded my-2 my-sm-0" style={{background: '#5b5a5d'}}>
                  <LanguageSwitcher en es fr urls={language_links}/>
                </div>
              }
            </div>
          </div>
          <div className="justify-content-center pt-2 pt-md-0 mt-md-0 search-box-content">
            <SearchBox productLink={product_link} user={user}/>
          </div>
        </div>
      </div>
    )
  }

  const ContactLink = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen)
    const {i18n} = useTranslation()

    return (
      <React.Fragment>
        <NavItem>
          <NavLink active={false} className="text-white px-3" style={{cursor: 'pointer'}} onClick={() => toggle()}>
            <strong>{i18n.t('navbar.contact')}</strong>
          </NavLink>
        </NavItem>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          header={i18n.t('navbar.contact_link.modal_title')}
        >
          <ContactForm/>
        </Modal>
      </React.Fragment>
    )
  }

  const SignOutLink = ({endpoint}) => {
    const logoutRequest = () => {
      new ApiFetcher({
        key: 'sign_out',
        method: 'DELETE',
        endpoint: endpoint
      }).call()
        .then(({redirect_to}) => {
          window.location.replace(redirect_to)
        })
    }

    return (
      <a className="text-white" onClick={logoutRequest} style={{cursor: 'pointer'}}>
        <span className="mr-1">{user.username}</span>
        <i className="fas fa-sign-out-alt"/>
      </a>
    )
  }

  if (!show_menu) { return '' }

  const brand_redirect_url = theme && theme.settings && theme.settings.brand_logo && theme.settings.brand_logo.redirect_url

  const Brand = () => {
    const clientLogoWidth = get(user, 'user_data.client.attachments.settings.brand_logo.width')
    const clientLogoHeight = get(user, 'user_data.client.attachments.settings.brand_logo.height')

    const logoThemeWidth = get(theme, 'settings.brand_logo.width', '100%')
    const logoThemeHeight = get(theme, 'settings.brand_logo.height', '35px')
    const imageRendering = get(theme, 'settings.brand_logo.image_rendering', 'auto')

    if (theme && theme.brand_logo_url) {
      return <img src={theme.brand_logo_url} width={clientLogoWidth || logoThemeWidth} height={clientLogoHeight || logoThemeHeight} style={{imageRendering: imageRendering}}/>
    } else if (theme && theme.code) {
      return theme.code
    } else {
      return themeBydomain().name
    }
  }

  const CartIcon = (props) => {
    const [open, setOpen] = useState(false)
    const {items} = props

    useEffect(() => {
      if (auto_open_cart && cart && cart.items.length > 0) {
        setOpen(true)
      }
    }, [cart])

    const Bubble = () => {
      return (
        <div style={{
          position: 'absolute',
          color: 'white',
          width: '17px',
          height: '17px',
          background: 'red',
          bottom: '0px',
          left: '5px',
          textAlign: 'center',
          borderRadius: '30px',
          fontWeight: 'bold',
          fontSize: '0.8em'
        }}>
          {items.length}
        </div>
      )
    }

    return (
      <React.Fragment>
        {open &&
          <CartSummary cart={cart} params={cart_params} setOpen={setOpen}/>
        }
        <NavItem
          onClick={() => setOpen(!open)}
          style={{position: 'relative', cursor: 'pointer'}}
        >
          {cart &&
            <Bubble/>
          }

          <NavLink className="text-white px-3">
            <strong><i class="fa fa-shopping-cart"/></strong>
          </NavLink>
        </NavItem>
      </React.Fragment>

    )
  }

  return (
    <AppProvider user={user}>
      <div className="shadow-sm">
        <Navbar dark expand="lg" className="py-2">
          <div className="container">
            <NavbarBrand className="d-flex align-items-center" tag="div">
              <span style={{fontSize: '1.35em'}}>
                <div className="d-flex flex-column flex-sm-row">
                  <div>
                    <a href={brand_redirect_url ? brand_redirect_url : root} className="text-white text-capitalize">
                      <Brand/>
                    </a>
                  </div>
                  {destination_id && (destinations && destinations.length > 1) &&
                    <React.Fragment>
                      {isBrowser &&
                        <span className="dots-separator ml-4 px-1" style={{borderLeft: '1px dotted white', width: '10px', height: '40px'}}/>
                      }
                      <div className="ml-0 ml-sm-3">
                        <DestinationSelector url={destination_link} destination_id={destination_id} change_destination_link={change_destination_link}/>
                      </div>
                    </React.Fragment>
                  }
                </div>
              </span>
              {isBrowser &&
                <React.Fragment>
                  <span className="dots-separator ml-4 px-1" style={{borderLeft: '1px dotted white', width: '10px', height: '40px'}}/>
                  <span className="d-flex align-items-center ml-2">
                    <LanguageSwitcher en es fr urls={language_links}/>
                  </span>
                </React.Fragment>
              }
            </NavbarBrand>
            <NavbarToggler onClick={toggle}/>
            <Collapse isOpen={isOpen} navbar>
              <Nav className="justify-content-end ml-auto" navbar>
                {links.map(({label, link, active, links}, i) => (
                  <React.Fragment key={'i' + i}>
                    {links && links.length > 0 ? (
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                          <strong className="menu-dropdown-label text-white mr-1">{label}</strong>
                        </DropdownToggle>
                        <DropdownMenu right>
                          {links.map(({label, link, active}, s) => (
                            <DropdownItem key={'s' + s} onClick={() => goToPage(link)}>
                              {label}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    ) : (
                      <NavItem>
                        <NavLink active={active} href={link} className="text-white px-3">
                          <strong>{label}</strong>
                        </NavLink>
                      </NavItem>
                    )}
                  </React.Fragment>
                ))}
                <ContactLink/>
                {enable_cart &&
                  <CartIcon {...cart}/>
                }
              </Nav>
              {!['b2c', 'staff'].includes(channel) &&
                <NavbarText className="ml-3">
                  <SignOutLink endpoint={logout_link}/>
                </NavbarText>
              }
            </Collapse>
          </div>
        </Navbar>
        <TopBar/>
      </div>
    </AppProvider>
  )
}

export default AppNavbar
